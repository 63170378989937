import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { concat, get, sortBy } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { parseArticles, parseBodySections } from '../common/content';
import { ArticleSection, ArticleSectionLayout } from '../components/article/section';
import { Sections } from '../components/layout/sections';
import { ArticleDynamicList } from '../components/article/dynamicList';
import { HeadingSize, HeadingVariant } from '../components/common/heading/heading.types';
import { Theme } from '../theme/color';

const FEATURED_SIZE = 3;

export const query = graphql`
  query TopicQuery($id: String!, $originalId: String!, $categories: [String]!, $initialSize: Int!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsTopic(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      body {
        ... on DatoCmsCategory {
          model {
            apiKey
          }
          locale
          slug
          id
          originalId
          name
          theme
        }
      }
      headingMore
    }
    versions: allDatoCmsTopic(
      filter: {slug: {ne: null}, originalId: {eq: $originalId}}
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
    articles: allDatoCmsArticle(
      filter: {slug: {ne: null}, topics: {elemMatch: {id: {eq: $id}}}},
      sort: {fields: meta___firstPublishedAt, order: DESC},
      limit: $initialSize
    ) {
      nodes {
        ...ArticleCard
      }
      totalCount
    }
    articlesByCategory: allDatoCmsArticle(
      filter: {slug: {ne: null}, topics: {elemMatch: {id: {eq: $id}}}, category: {id: {in: $categories}}},
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ) {
      group(
        field: category___originalId,
        limit: 10
      ) {
        nodes {
          ...ArticleCard
        }
        originalId:fieldValue
      }
    }
  }
`;

const Topic = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const versions = get(data, 'versions.nodes');

  const articles = get(data, 'articles.nodes');
  const articlesTotalCount = get(data, 'articles.totalCount',0);

  const articlesByCategory = get(data, 'articlesByCategory.group');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  const featuredArticles = articles.slice(0,FEATURED_SIZE);
  const otherArticles = articles.slice(FEATURED_SIZE);

  const featured = {
    heading: page.name,
    headingSize: HeadingSize.LARGE,
    headingVariant: HeadingVariant.OVERLINE,
    articles: parseArticles(featuredArticles),
    layout: ArticleSectionLayout.FEATURED,
    theme: Theme.WHITE
  }

  const body = parseBodySections(page.body,{articlesByCategory});
 
  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <ArticleSection {...featured} />
      <Sections content={body} />
      {otherArticles.length > 0 && <ArticleDynamicList borderTop={body.length === 0 || body[body.length -1].theme === Theme.OFF_WHITE} heading={page.headingMore} headingSize={HeadingSize.SMALL} headingVariant={HeadingVariant.OVERLINE} items={parseArticles(otherArticles)} excludeOriginalIds={concat(featuredArticles.map(a => a.originalId),otherArticles.map(a => a.originalId))} topicOriginalId={pageContext.originalId} total={Math.max(articlesTotalCount - pageContext.initialSize,0)} paginationSize={pageContext.paginationSize} />}
    </Fragment>
  );
};

export default Topic;